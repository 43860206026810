import request from "./http";

/**
 * 查询公司全部员工
 * @param {string} cname
 * @returns
 */
export const getAllCompanyStaff = async (params, options = {}) => {
    return await request.get("/api/company/GetComUserAll", params, options);
};
export const getAllCompanyStaffById = async (params, options = {}) => {
    return await request.get("/api/company/GetComUserAllByCompanyId", params, options);
};

export const getAllCompany = async (params, options = {}) => {
    return await request.get("/api/company/getAllCompany", params, options);
};

/**
 * 公司申请认证
 * @param {number} uid
 * @param {string} companyName
 * @param {string} companyPhone
 * @param {string} companyAddress
 * @param {string} businessLicense
 * @param {string} invitationCode
 * @returns
 */
export const companyApplyCertification = async (params) => {
    return await request.post("/api/company/EntCompany", params);
};

/**
 * 通过公司名查询公司信息
 * @param {string} cname
 * @returns
 */
export const getCompanyInfoByName = async (params) => {
    return await request.get("/api/company/GetComInfobyName", params);
};

/**
 * 通过公司名查询公司员工 (暂未使用，如果用了记得把括号里的东西删掉)
 * @param {string} cname
 * @param {string} pagenum
 * @param {string} pagesize
 * @returns
 */
export const getCompanyStaffByName = async (params) => {
    return await request.get("/api/company/GetCombynameOne", params);
};

/**
 * 公司注销
 * @param {number} uid
 * @param {number} cid
 * @returns
 */
export const companyLogout = async (params) => {
    return await request.post("/api/company/CompanyDet", params);
};

/**
 * 给员工分配角色 (暂未使用，如果用了记得把括号里的东西删掉)
 * @param {number} uid
 * @param {number} cid
 * @param {string} role
 * @returns
 */
export const updateStaffRole = async (params) => {
    return await request.post("/api/company/EntRole", params);
};

/**
 * 公司添加员工
 * @param {number} uid
 * @param {number} cid
 * @returns
 */
export const companyAddStaff = async (params) => {
    return await request.post("/api/company/Comadduser", params);
};

/**
 * 查询用户公司角色 (暂未使用，如果用了记得把括号里的东西删掉)
 * @param {number} cid
 * @param {number} uid
 */
export const getStaffCompanyRole = async (params) => {
    return await request.post("/api/company/CurrentUserComRole", params);
};

/**
 * 公司获取员工申请信息
 * @param {string} cid
 * @param {string} isaccess
 * @param {string} pagenum
 * @param {string} pagesize
 */
export const getStaffApplyList = async (params) => {
    return await request.get("/api/company/GetUserinterComInfo", params);
};

/**
 * 通过公司名查询公司员工
 * @param {string} cname
 * @param {string} pagenum
 * @param {string} pagesize
 * @returns
 */
export const getCompanyStaffById = async (params) => {
    return await request.get("/api/company/GetCombyIdPage", params);
};

/**
 * 移交管理员
 * @param {number} uid
 * @param {number} cid
 * @param {number} uid2
 */
export const transferAdminRole = async (params) => {
    return await request.post("/api/company/TurnOverRole", params);
};

/**
 * 公司邀请员工
 * @param {number} cid
 * @param {string} phone
 */
export const companyInviteStaff = async (params) => {
    return await request.post("/api/company/Encryupcid", params);
};

export const companyInviteStaffByPhone = async (params) => {
    return await request.post("/api/company/InviteByPhone", params);
};

/**
 * 员工同意公司邀请校验是否本用户
 * @param {string} key
 * @param {string} channelCode
 */
export const agreeCompanyInviteAndIsSelf = async (params) => {
    return await request.post("/api/company/ComaInvite", params);
};

/**
 * 员工同意公司邀请 (暂未使用，如果用了记得把括号里的东西删掉)
 * @param {string} key
 * @param {string} channelCode
 */
export const agreeCompanyInvite = async (params) => {
    return await request.post("/api/company/ComaddEntuser", params);
};

/**
 * 删除员工加入申请
 * @param {number} uid
 * @param {number} cid
 */
export const delStaffApply = async (params) => {
    return await request.post("/api/company/UserComCheckDet", params);
};

/**
 * 公司移除员工
 * @param {number} cid
 * @param {number} uid
 */
export const companyDelStaff = async (params) => {
    return await request.post("/api/company/ComRemoveUser", params);
};

/**
 * 查询当前用户申请成功的公司和角色
 */
export const getUserSuccRoleAndCompany = async () => {
    return await request.get("/api/company/CurrentUserComsRole");
};

/**
 * 获取用户微信个人信息
 * @param {string} access_token
 * @param {string} openid
 */
export const getWxUserInfo = async (params) => {
    return await request.get("/api/company/GetWXUserNameAva", params);
};
