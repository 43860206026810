export default [
    {
        path: "/pay/charge",
        name: "payCharge",
        component: () => import("@/views/PayMent/component/PayMentCharge"),
        meta: { title: "充值中心" },
    },
    {
        path: "/pay/ticket",
        name: "payTicket",
        component: () => import("@/views/PayMent/component/PayMentTicket"),
        meta: { title: "开票中心" },
    },
    {
        path: "/pay/ticketList",
        name: "payTicketList",
        component: () => import("@/views/PayMent/component/PayMentTicketList"),
        meta: { title: "发票管理" },
    },
    {
        path: "/pay/chargeHistory",
        name: "payChargeHistory",
        component: () =>
            import("@/views/PayMent/component/PayMentChargeHistory"),
        meta: { title: "充值记录" },
    },
    {
        path: "/pay/accountRecord",
        name: "payAccountRecord",
        component: () =>
            import("@/views/PayMent/component/PayMentAccountRecord"),
        meta: { title: "消费记录" },
    },
];
